import gql from "graphql-tag";

const migrationMergeRequestQuery = gql`
  query migrationIssues {
    project(fullPath: "gitlab-org/gitlab") {
      mergeRequests(
        labels: "Pajamas Migration Day"
        createdAfter: "2023-10-11"
        createdBefore: "2023-10-15"
      ) {
        nodes {
          id
          webUrl
          state
          labels {
            nodes {
              title
            }
          }
          approvedBy {
            nodes {
              id
              username
              name
              avatarUrl
            }
          }
          author {
            id
            username
            name
            avatarUrl
          }
        }
      }
    }
  }
`;

export default migrationMergeRequestQuery;
