<script setup>
const dripWidth = 40;

const numberOfDrips = Math.floor(window.innerWidth / dripWidth);
const drips = Array(numberOfDrips)
  .fill({})
  .map((x, id) => ({
    id,
    offset: `${Math.floor(Math.random() * 20)}vh`,
    speed: `${Math.floor(Math.random() * 20) + 100}s`,
  }));
</script>

<template>
  <div class="drips">
    <div
      v-for="drip in drips"
      class="drip"
      :key="drip.id"
      :style="{
        '--drip-offset': drip.offset,
        '--drip-speed': drip.speed,
      }"
    ></div>
  </div>
</template>

<style scoped>
@keyframes drip {
  from {
    transform: translateY(-100vh);
  }
  to {
    transform: translateY(0);
  }
}

.drips {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  display: flex;
}
.drip {
  /* height: 200vh; */
  height: calc(var(--drip-offset) + 120vh);
  animation: drip var(--drip-speed) ease-in-out;
  width: 2em;
  display: block;
  flex-grow: 1;
  background: var(--color-orange-03);
  border-bottom-left-radius: 100em;
  border-bottom-right-radius: 100em;
}
</style>
