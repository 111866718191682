import Vue from "vue";
import App from "./App.vue";
import setConfigs from "@gitlab/ui/dist/config";
import VueApollo from "vue-apollo";
import ApolloClient from "apollo-boost";
import { GlToast } from "@gitlab/ui";

const apolloClient = new ApolloClient({
  uri: "https://gitlab.com/api/graphql",
});

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

setConfigs();

Vue.config.productionTip = false;
Vue.use(GlToast);
Vue.use(VueApollo);

new Vue({
  apolloProvider,
  render: (h) => h(App),
}).$mount("#app");
