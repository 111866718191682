<template>
  <div class="podium">
    <div v-for="(user, index) in users" :key="user.id" class="user">
      <MigrationAvatar :user="user" :position="index" />
      <div class="medal">{{ ["🥇", "🥈", "🥉"][index] }}</div>
    </div>
  </div>
</template>

<script>
import MigrationAvatar from "./MigrationAvatar.vue";

export default {
  name: "MigrationPodium",
  components: {
    MigrationAvatar,
  },
  props: {
    users: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.podium {
  display: grid;
  max-width: 500px;
  margin: 0 auto;
  align-items: center;
  grid-template-areas: "chicken winner dinner";
  grid-template-columns: 1fr 1.6fr 1fr;
  gap: 1em;
  text-align: center;
}

.podium > .user:first-child {
  grid-area: winner;
}

.medal {
  font-size: 2.6em;
}

img {
  border: 0.6em solid;
}
</style>
