import calculateLabelScoreFor from "@/helpers/calculateLabelScoreFor";

const calculateMrScoreFor = (mr) => {
  const labels = mr.labels?.nodes.map((label) => label.title);
  const labelScore = calculateLabelScoreFor(labels);

  switch (mr.state) {
    case "merged":
      return labelScore * 3;
    case "opened":
      return labelScore;
    case "closed":
    default:
      return 0;
  }
};

export default calculateMrScoreFor;
