<template>
  <div class="wrapper">
    <blood-drips v-if="spook" />
    <GlToggle
      v-model="spook"
      label="Spooky?"
      class="toggle"
      @change="updateSpook"
    ></GlToggle>
    <main id="app" :class="{ spook }">
      <div v-if="$apollo.loading" class="loading"></div>
      <div class="empty card" v-else-if="items.length < 1">
        <h1>Nothing yet</h1>
        <p>
          There are no contributions yet.
          <a href="https://picker.pajamasmigrationday.com/">Kick us off</a>.
        </p>
      </div>
      <div class="loaded" v-else>
        <div class="card">
          <MigrationPodium :users="podiumUsers"></MigrationPodium>
        </div>
        <div class="card">
          <GlTableLite :items="items" :fields="fields"></GlTableLite>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { GlTableLite, GlToggle } from "@gitlab/ui";
import migrationMergeRequestQuery from "@/graphql/mergeRequests.js";
import MigrationPodium from "@/components/MigrationPodium.vue";
import getDiffFor from "@/helpers/getDiffFor";
import calculateMrScoreFor from "@/helpers/calculateMrScoreFor";
import BloodDrips from "./components/BloodDrips.vue";
const SPOOK_PREF_KEY = "SPOOK_KEY";

export default {
  name: "App",
  components: {
    GlTableLite,
    MigrationPodium,
    BloodDrips,
    GlToggle,
  },
  data: () => ({
    fields: [
      { key: "position", label: "#" },
      { key: "name", label: "Name", tdClass: "name-data" },
      { key: "score", label: "Score" },
    ],
    spook: true,
  }),
  apollo: {
    mergeRequests: {
      query: migrationMergeRequestQuery,
      update(data) {
        const mrs = data?.project?.mergeRequests?.nodes;
        if (
          mrs &&
          this.mergeRequests &&
          mrs.length > this.mergeRequests.length
        ) {
          getDiffFor(mrs, this.mergeRequests).forEach(this.notify);
        }
        return mrs;
      },
      pollInterval: 60 * 1000,
    },
  },
  computed: {
    podiumUsers() {
      return this.users.slice(0, 3);
    },
    items() {
      return this.users.map(({ name, score, mrs }, i) => ({
        name: mrs.length >= 3 ? `🎃 ${name}` : name,
        score,
        position: i + 1,
      }));
    },
    userMap() {
      const users = {};
      const updateUser = (user, mr, isApprover) => {
        const existingUser = users[user.id];
        const mrScore = calculateMrScoreFor(mr);
        const userScore = isApprover ? Math.floor(mrScore * 0.5) : mrScore;

        if (existingUser) {
          if (!isApprover) {
            existingUser.mrs.push(mr);
          }
          existingUser.score += userScore;
        } else {
          users[user.id] = {
            ...user,
            score: userScore,
            mrs: isApprover ? [] : [mr],
          };
        }
      };

      this.mergeRequests.forEach((mr) => {
        const { author } = mr;

        updateUser(author, mr);

        const approvers = mr.approvedBy.nodes;
        if (approvers.length) {
          approvers.forEach((approver) => updateUser(approver, mr, true));
        }
      });
      return users;
    },
    users() {
      return Object.values(this.userMap).sort((a, b) =>
        a.score > b.score ? -1 : 1
      );
    },
  },
  beforeMount() {
    this.spook = JSON.parse(
      window.localStorage.getItem(SPOOK_PREF_KEY) || true
    );
  },
  methods: {
    notify(mr) {
      const { author, state } = mr;
      const { name } = author;

      this.$toast.show(`${name} just ${state} an MR! 🎉`);

      if (this.userMap[author.id].mrs.length === 2) {
        setTimeout(() => {
          this.$toast.show(
            `${name} opened their third MR, they're eating for free! 🎃`
          );
        }, 2000);
      }
    },
    updateSpook() {
      window.localStorage.setItem(SPOOK_PREF_KEY, this.spook);
    },
  },
};
</script>

<style>
@import "@gitlab/ui/dist/index.css";
@import "./assets/main.css";

.wrapper {
  width: 100vw;
  min-height: 100vh;
  max-width: 100%;
}

.loaded {
  display: grid;
  gap: 1em;
}

.toggle {
  position: absolute;
  top: 1.6rem;
  right: 1.6rem;
  z-index: 99;
}
.gl-toggle-wrapper {
  line-height: 1.6em;
  gap: 0.6em;
}
</style>
