const calculateLabelScoreFor = (labels) => {
  if (
    labels.includes("component:dropdown") ||
    labels.includes("component:dropdown-collapsible-listbox") ||
    labels.includes("component:dropdown-combobox") ||
    labels.includes("component:dropdown-disclosure")
  ) {
    return 10;
  }

  if (labels.includes("component:button")) {
    return 5;
  }

  return 1;
};

export default calculateLabelScoreFor;
