<template>
  <img :alt="user.name" :src="avatarSrc" />
</template>

<script>
export default {
  name: "MigrationAvatar",
  props: {
    user: {
      type: Object,
      required: true,
    },
    position: {
      type: Number,
      required: false,
    },
  },
  computed: {
    avatarSrc() {
      const gitlabTLD = "https://gitlab.com/";

      return this.user.avatarUrl.replace(/^\//, gitlabTLD);
    },
  },
};
</script>

<style scoped>
img {
  border-radius: 100%;
  display: inline-block;
  width: 100%;
  height: auto;
}
</style>
